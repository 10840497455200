import React from 'react';

export const MoonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18.005"
    style={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      fill="var(--baseTextColor)"
      d="M9.096 18.005A9.1 9.1 0 012.662 2.47 9.313 9.313 0 016.914.044 1.212 1.212 0 017.242 0a1.262 1.262 0 011.164 1.756 5.821 5.821 0 001.056 6.779 5.778 5.778 0 004.113 1.7 5.838 5.838 0 002.622-.626.743.743 0 01.174-.062 1.262 1.262 0 011.207.263 1.267 1.267 0 01.374 1.3 9.287 9.287 0 01-2.42 4.229 9.038 9.038 0 01-6.436 2.666zM6.842 1.606a7.894 7.894 0 00-3.13 1.916 7.613 7.613 0 1010.767 10.766 7.825 7.825 0 001.91-3.129 7.334 7.334 0 01-2.814.565 7.255 7.255 0 01-5.164-2.138 7.284 7.284 0 01-1.569-7.98z"
    />
  </svg>
);

export const SunIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
    <g fill="var(--baseTextColor)">
      <path d="M10 14.544a4.545 4.545 0 01-3.213-7.76 4.545 4.545 0 016.428 6.428A4.516 4.516 0 0110 14.544zm0-7.273a2.727 2.727 0 101.928.8A2.709 2.709 0 0010 7.272v-.001z" />
      <path d="M10 2.727a.909.909 0 01-.909-.909V.909A.909.909 0 0110 0a.909.909 0 01.909.909v.909a.909.909 0 01-.909.909z" />
      <path d="M10 20a.909.909 0 01-.909-.909v-.909a.909.909 0 111.818 0v.909A.909.909 0 0110 20z" />
      <path d="M4.486 5.395a.906.906 0 01-.643-.266l-.644-.644a.909.909 0 010-1.286.909.909 0 011.286 0l.644.644a.909.909 0 010 1.286.906.906 0 01-.643.266z" />
      <path d="M16.159 17.068a.906.906 0 01-.643-.266l-.643-.644a.909.909 0 011.285-1.285l.644.644a.909.909 0 01-.643 1.552v-.001z" />
      <path d="M15.515 5.395a.906.906 0 01-.642-.266.909.909 0 010-1.286l.644-.644a.909.909 0 011.286 0 .909.909 0 010 1.286l-.645.644a.906.906 0 01-.643.266z" />
      <path d="M3.842 17.068a.906.906 0 01-.643-.266.909.909 0 010-1.286l.644-.643a.909.909 0 011.286 1.285l-.644.644a.906.906 0 01-.643.266z" />
      <path d="M19.091 10.909h-.909a.909.909 0 110-1.818h.909a.909.909 0 110 1.818z" />
      <path d="M1.818 10.909H.909A.909.909 0 010 10a.909.909 0 01.909-.909h.909a.909.909 0 01.909.909.909.909 0 01-.909.909z" />
    </g>
  </svg>
);
