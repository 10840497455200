import React from 'react';

export const Logo = () => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="33.000000pt" height="33.000000pt" viewBox="0 0 283.000000 363.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,363.000000) scale(0.100000,-0.100000)" stroke="none">
<path 
fill="var(--primaryColor)"
d="M1815 3561 c-64 -26 -92 -50 -109 -92 l-16 -40 -21 20 c-29 27 -79
37 -91 20 -5 -8 -7 -31 -5 -52 2 -34 1 -38 -18 -32 -74 24 -98 11 -88 -49 l5
-32 -48 0 -49 1 3 -48 c3 -47 3 -48 -23 -41 -51 13 -67 -35 -30 -92 l15 -24
-43 0 c-65 0 -71 -13 -37 -79 32 -59 28 -70 -20 -71 -49 -1 -53 -12 -20 -73
l30 -57 -44 0 c-25 0 -48 -5 -52 -11 -3 -6 19 -35 51 -65 47 -45 54 -55 38
-60 -39 -12 -83 -34 -83 -41 0 -12 12 -26 48 -55 l33 -27 -45 -22 c-40 -18
-44 -24 -35 -40 5 -10 13 -19 17 -19 4 -1 18 -10 31 -21 l24 -20 -22 -23 c-29
-31 -22 -53 31 -90 l42 -29 -37 -14 c-20 -9 -37 -20 -37 -27 0 -6 20 -22 44
-35 27 -14 46 -31 48 -44 6 -37 272 -175 413 -213 28 -8 76 -17 108 -20 56 -6
60 -5 93 27 19 18 34 38 34 45 0 7 3 14 8 16 4 2 20 23 36 47 26 40 27 48 17
76 -28 78 -5 156 82 284 72 105 74 118 27 186 -15 22 -41 66 -58 97 -17 31
-36 65 -41 75 -44 78 -105 226 -125 303 -3 8 -5 15 -6 15 -1 0 -3 7 -5 15 -2
8 -11 47 -20 85 -19 81 -23 285 -7 327 15 35 6 39 -43 19z m20 -330 c-3 -6
-11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m124 -590 l49 -103 -31
-43 c-18 -23 -37 -51 -44 -61 -12 -18 -12 -18 -6 1 3 11 19 40 36 65 l29 44
-29 56 c-17 30 -41 82 -53 115 -13 33 -29 69 -36 80 -6 11 -15 31 -18 45 -7
26 18 -21 103 -199z"/>
<path
fill="var(--headingsColor)" 
d="M338 2689 c-10 -5 -18 -16 -18 -24 0 -26 -21 -27 -41 -1 -41 52 -93
25 -98 -50 -1 -7 -12 -9 -27 -6 -30 6 -44 -10 -44 -51 0 -20 -7 -30 -25 -37
-30 -11 -32 -35 -8 -86 17 -34 17 -36 0 -45 -24 -14 -22 -28 13 -83 40 -62 40
-69 6 -75 l-28 -6 31 -32 c17 -18 31 -36 31 -40 0 -4 16 -24 36 -44 32 -32 35
-39 24 -59 -15 -28 -5 -74 15 -66 9 3 15 0 15 -9 0 -8 8 -19 17 -25 13 -8 14
-13 6 -16 -23 -10 -13 -50 20 -82 18 -18 38 -32 44 -32 20 0 24 -18 8 -34 -23
-23 -4 -50 54 -78 42 -20 49 -26 44 -46 -5 -22 19 -62 38 -62 4 0 19 -4 34
-10 22 -8 24 -13 15 -30 -8 -16 -6 -22 12 -34 13 -8 42 -17 65 -21 41 -6 41
-7 30 -32 -16 -35 11 -53 81 -53 39 0 61 -6 79 -20 14 -11 41 -20 59 -20 19 0
34 -4 34 -8 0 -15 50 -33 119 -43 114 -16 135 -20 156 -29 34 -15 125 -20 153
-8 15 5 50 11 78 12 34 2 61 10 82 24 17 13 45 22 65 22 l35 0 -13 -58 c-7
-31 -16 -70 -18 -87 -3 -16 -16 -78 -30 -137 -27 -123 -3 -90 -299 -403 -169
-178 -195 -229 -142 -282 l26 -26 -21 -44 c-28 -58 -27 -104 2 -131 13 -12 35
-22 49 -22 23 0 26 -5 32 -50 4 -33 13 -54 27 -64 25 -17 75 -13 99 9 21 19
34 19 50 0 35 -43 145 -16 169 42 11 27 15 28 76 28 91 0 97 8 124 175 30 177
27 160 61 350 16 91 32 175 35 187 3 13 31 41 63 64 31 23 63 49 69 57 27 33
22 2 -8 -57 -17 -35 -29 -64 -27 -67 7 -6 102 28 110 40 4 6 8 23 8 38 0 19 9
31 33 46 34 20 80 88 92 135 4 16 34 48 75 82 153 125 298 299 348 417 23 55
26 76 25 163 0 87 -5 114 -37 209 -47 140 -47 187 -3 227 39 36 72 51 177 80
44 11 80 24 80 28 0 3 -33 26 -74 51 -41 25 -80 55 -85 68 -6 13 -23 25 -40
28 -16 4 -32 12 -35 19 -3 8 -25 25 -50 40 -34 20 -59 27 -103 27 -136 2 -216
-65 -269 -225 -48 -146 -100 -232 -216 -362 -37 -41 -39 -42 -85 -34 -193 31
-416 134 -588 269 -58 45 -63 47 -140 53 -129 9 -297 75 -439 172 -32 22 -68
47 -80 54 -43 30 -173 170 -190 205 -18 39 -22 41 -48 25z m2088 -316 c-10
-10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m156 5 c-6 -6 -16 -7 -22
-3 -9 6 -9 9 1 16 17 10 34 0 21 -13z m50 -38 l33 -20 -38 2 c-39 1 -53 11
-42 28 8 13 9 13 47 -10z m-2062 -104 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4
0 6 5 11 10 11 6 0 10 -2 10 -4z m199 -45 c58 -30 179 -58 262 -60 l76 -2 73
-70 c40 -38 76 -69 81 -69 4 0 20 -11 35 -25 15 -14 51 -39 81 -56 29 -17 53
-32 53 -35 0 -7 -75 33 -133 71 -54 34 -71 49 -147 121 -43 42 -49 44 -118 49
-40 3 -106 15 -147 25 -71 18 -165 55 -165 65 0 8 10 6 49 -14z m336 -261 c3
-5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m1330
-119 c4 -76 -17 -170 -52 -236 -27 -51 -110 -165 -120 -165 -5 0 2 11 45 64
66 83 112 218 113 334 1 88 9 89 14 3z m-970 49 c3 -6 -1 -7 -9 -4 -18 7 -21
14 -7 14 6 0 13 -4 16 -10z m616 -73 c-13 -13 -15 1 -4 30 7 17 8 17 11 -1 2
-10 -1 -23 -7 -29z m-24 -53 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m-17 -28 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z"/>
</g>
</svg>

  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="30"
  //   height="30"
  //   viewBox="0 0 30 30"
  // >
  //   <path
  //     d="M823.058,231.951a15,15,0,1,1,15-15,3.838,3.838,0,1,1-7.676,0,7.327,7.327,0,1,0-7.327,7.327q.262,0,.522-.018a12.919,12.919,0,0,0,7.673,5.254A15,15,0,0,1,823.058,231.951Z"
  //     transform="translate(-808.058 -201.951)"
  //     fill="var(--headingsColor)"
  //   />
  // </svg>
);
